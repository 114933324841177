import React from 'react'
import {HiArrowNarrowRight} from 'react-icons/hi'
import { Link } from 'react-scroll'

const Home = () => {
  return (
    <div name='home' className='w-full h-screen bg-[#193082]'>

    {/* Container */}
    <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
      <p className='text-xl text-[#5B9A8B]'>Hello! My name is</p>
      <h1 className='text-4xl sm:text-7xl font-bold text-white'>Daniel Donaldson</h1>
      <h2 className='text-4xl sm:text-7xl font-bold text-[#8892b0]'>I'm a Full Stack Developer.</h2>
      <p className='text-xl text-[#8892b0] py-4 max-w-[700px]'>I'm a full stack developer focused on building an exceptional digital experience. Currently, I specialize in making responsive and scalable full-stack web applications</p>
    <div>
      <button className='text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-[#5B9A8B]'>
      <Link to='work' smooth={true} duration={500}>View Work</Link>
        <span className='group-hover:rotate-90 duration-300'>
          <HiArrowNarrowRight className='ml-3'/> 
        </span>
        </button>
    </div>
    </div>
    </div>
  
  )
}

export default Home